import { useState, useEffect } from "react";
import { useApiClient } from "../utils/ApiClient";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box, Grid, TextField, Tooltip, Button } from "@mui/material";
import { RequestStatusAlert, RequestStatus, defaultStatus } from "../components/RequestStatus";

import Card from "../components/Card";
import ViewHeader from "../components/ViewHeader";
import EmailsField from "../components/EmailsField";
import CustomRefTimeSettings from "../components/settings/CustomRefTimeSettings";

export default function Settings () {
  const apiClient = useApiClient();

  const [settings, setSettings] = useState({
    checkFrequency: 5,
    sendSingleAfterMinutes: 20,
    startTimeOffset: 10
  })

  const [morningCheck, setMorningCheck] = useState({
    start: null,
    end: null
  });

  const [eveningCheck, setEveningCheck] = useState({
    start: null,
    end: null
  });

  const [emails, setEmails] = useState(new Set());
  const [saveRequestStatus, setSaveRequestStatus] = useState(defaultStatus());
  
  const addEmail = (email) => {
    setEmails(new Set([...emails, email]));
  }

  const deleteEmail = (email) => {
    let updatedEmails = new Set([...emails]);
    updatedEmails.delete(email);

    setEmails(updatedEmails);
  }

  const updateSettings = () => {
    apiClient.updateSettings({
      ...settings,
      morningCheck: morningCheck,
      eveningCheck: eveningCheck,
      notificationEmails: [...emails]
    }).then((response) => {
      setSaveRequestStatus({
        status: RequestStatus.SUCCESS,
        message: "Settings Updated"
      })
    }).catch((error) => {
      if (error.response) {
        setSaveRequestStatus({
          status: RequestStatus.FAILURE,
          message: error.response.data.message
        });
      } else {
        setSaveRequestStatus({
          status: RequestStatus.FAILURE,
          message: error.toString()
        });
      }
    });
  }

  useEffect(() => {
    apiClient.settings().then((remoteSettings) => {
      setSettings({
        checkFrequency: remoteSettings.checkFrequency,
        sendSingleAfterMinutes: remoteSettings.sendSingleAfterMinutes,
        startTimeOffset: remoteSettings.startTimeOffset
      });

      setMorningCheck({ ...remoteSettings.morningCheck });
      setEveningCheck({ ...remoteSettings.eveningCheck });
      setEmails(new Set([...remoteSettings.notificationEmails]));
    });
  }, []);

  return (
    <Box>
      <ViewHeader
        title={"Settings"}
        onActionClick={() => {}}
      />

      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={saveRequestStatus.status}
        message={saveRequestStatus.message}
      />

      <Card title="General">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              label="Check Frequency (Minutes)"
              value={settings.checkFrequency}
              onChange={(event) => {
                setSettings({
                  ...settings,
                  checkFrequency: event.target.value
                })
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Tooltip title="The time interval (in minutes) after which a request should be sent if one model is missing">
              <TextField
                fullWidth
                type="number"
                label="Send Single After Minutes"
                value={settings.sendSingleAfterMinutes}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    sendSingleAfterMinutes: event.target.value
                  })
                }}
              />
            </Tooltip>
          </Grid>

          <Grid item xs={4}>
            <Tooltip title="The time interval (in hours) to be added to the job's start time upon submission">
              <TextField
                fullWidth
                type="number"
                label="Start Time Offset (Hours)"
                value={settings.startTimeOffset}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    startTimeOffset: event.target.value
                  })
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Card>

      <Card title="Morning Check" sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TimePicker
              label="Start Time (UTC)"
              renderInput={(params) => <TextField fullWidth {...params} />}
              value={morningCheck.start}
              onChange={(value) => {
                setMorningCheck({
                  ...morningCheck,
                  start: value
                })
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TimePicker
              label="End Time (UTC)"
              renderInput={(params) => <TextField fullWidth {...params} />}
              value={morningCheck.end}
              onChange={(value) => {
                setMorningCheck({
                  ...morningCheck,
                  end: value
                })
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Card title="Evening Check" sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TimePicker
              label="Start Time (UTC)"
              renderInput={(params) => <TextField fullWidth {...params} />}
              value={eveningCheck.start}
              onChange={(value) => {
                setEveningCheck({
                  ...eveningCheck,
                  start: value
                })
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TimePicker
              label="End Time (UTC)"
              renderInput={(params) => <TextField fullWidth {...params} />}
              value={eveningCheck.end}
              onChange={(value) => {
                setEveningCheck({
                  ...eveningCheck,
                  end: value
                })
              }}
            />
          </Grid>
        </Grid>
      </Card>

      <Card title="Notification Emails" sx={{ mt: 2 }}>
        <EmailsField
          emails={emails}
          onAdd={addEmail}
          onDelete={deleteEmail}
        />
      </Card>

      <Box
        sx={{ mt: 2, textAlign: "right" }}
      >
        <Button variant="contained" onClick={updateSettings}>
          Save
        </Button>
      </Box>

      <CustomRefTimeSettings sx={{ mt: 2 }} />
    </Box>
  )
}