import { useEffect, useState } from "react";
import { useApiClient } from "../../utils/ApiClient";
import { Box, Stack, Fab, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import CardLabel from "../CardLabel";
import AlertDialog from "../AlertDialog";
import AddRefTimeDialog from "./dialogs/AddRefTimeDialog";

import { customRefTimesColumns } from "../../data/columns";
import { RequestStatusAlert, RequestStatus, defaultStatus } from "../RequestStatus";

export default function CustomRefTimeSettings (props) {
  const apiClient = useApiClient();

  const [refTimes, setRefTimes] = useState([]);
  const [addRefTimeDialogOpen, setAddRefTimeDialogOpen] = useState(false);
  const [apiRequestStatus, setApiRequestStatus] = useState(defaultStatus());

  const [deleteDialogSettings, setDeleteDialogSettings] = useState({
    open: false,
    refTime: {
      id: 0,
      name: "",
      time: 0
    }
  })

  useEffect(() => {
    apiClient.customRefTimes().then((response) => {
      setRefTimes(response.data.refTimes);
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setApiRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not fetch refTimes: ${message}`
      })
    })
  }, [])

  const saveCustomRefTime = (newRefTime) => {
    apiClient.saveCustomRefTime(newRefTime).then((response) => {
      setApiRequestStatus({
        status: RequestStatus.SUCCESS,
        message: "refTime saved"
      })

      setRefTimes([
        ...refTimes,
        response.data
      ])
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setApiRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not save refTime: ${message}`
      })
    })
  }

  return (
    <Box sx={props.sx ?? {}}>
      <CardLabel text={"Custom Reference Times"} sx={{ mb: 1 }} />

      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={apiRequestStatus.status}
        message={apiRequestStatus.message}
      />

      <Box sx={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={refTimes}
          columns={[
            ...customRefTimesColumns,
            {
              field: "actions",
              type: "actions",
              width: 200,
              getActions: (params) => {
                return [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setDeleteDialogSettings({
                        refTime: params.row,
                        open: true
                      })
                    }}
                    label="Delete"
                  />
                ];
              }
            }
          ]}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <Stack direction="row" sx={{ flexDirection: "row-reverse" }}>
        <Fab
          color="primary"
          aria-label="add refTime"
          sx={{ mt: 2 }}
          onClick={() => setAddRefTimeDialogOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Stack>

      <AddRefTimeDialog
        open={addRefTimeDialogOpen}
        handleClose={() => {
          setAddRefTimeDialogOpen(false)
        }}
        handleSave={(refTime) => {
          setAddRefTimeDialogOpen(false)
          saveCustomRefTime(refTime)
        }}
      />

      <AlertDialog
        title={"Do you want to delete the custom refTime?"}
        content={`refTime ${deleteDialogSettings.refTime.name} (${deleteDialogSettings.refTime.time} hours) will be deleted. Your action cannot be undone.`}
        open={deleteDialogSettings.open}
        onClose={() => {
          setDeleteDialogSettings({
            ...deleteDialogSettings,
            open: false
          })
        }}
      >
        <Button
          onClick={() => {
            setDeleteDialogSettings({
              ...deleteDialogSettings,
              open: false
            })
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={() => {
            // Delete the custom refTime
            const refTimeId = deleteDialogSettings.refTime.id

            apiClient.deleteCustomRefTime(refTimeId).then((response) => {
              setApiRequestStatus({
                status: RequestStatus.SUCCESS,
                message: "refTime deleted"
              })

              setRefTimes(refTimes.filter((r) => r.id !== refTimeId))
            }).catch((error) => {
              const message = error.response ? error.response.data.message : error.toString();

              setApiRequestStatus({
                status: RequestStatus.FAILURE,
                message: `Failed to delete refTime: ${message}`
              })
            })

            setDeleteDialogSettings({
              ...deleteDialogSettings,
              open: false
            })
          }}
        >
          Confirm
        </Button>
      </AlertDialog>
    </Box>
  )
}