import { Grid, Typography, Box, IconButton, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AppleSwitch from "./AppleSwitch";

export default function DetailsHeader (props) {
  return (
    <Box sx={{ mb: 2, mt: 2 }}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h4" component="div">
            {props.title}
          </Typography>
          <Typography variant="body1" component="div">
            {props.subtitle}
          </Typography>
        </Grid>

        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {props.editing && (
            <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
              <Button
                aria-label="cancel"
                onClick={props.onCancel}
                color="error"
              >
                Cancel
              </Button>
              <Button
                aria-label="edit"
                onClick={props.onSave}
              >
                Save
              </Button>
            </Box>
          )}

          {!props.editing && (
            <Box>
              <IconButton
                onClick={props.enableEditing}
                sx={{ marginTop: "auto", marginBottom: "auto" }}
                size="large">
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={props.onDelete}
                sx={{ marginTop: "auto", marginBottom: "auto", mr: 1.5 }}
                size="large">
                <DeleteIcon />
              </IconButton>

              <AppleSwitch
                checked={props.enabled}
                onChange={props.onEnabledStatusChanged}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}