import { RequestStatus } from "../components/RequestStatus";

/**
 * Handle an API error, format it and set
 * it as the request status using the given
 * setter
 * 
 * @param {function} requestStatusSetter 
 * @param {object} error 
 */
const handleApiError = (requestStatusSetter, error) => {
  if (error.response) {
    requestStatusSetter({
      status: RequestStatus.FAILURE,
      message: error.response.data.message
    });
  } else {
    requestStatusSetter({
      status: RequestStatus.FAILURE,
      message: error.toString()
    });
  }
}

export {
  handleApiError
}