import { useEffect, useState } from "react";
import { handleApiError } from "../../utils/errors";
import { useApiClient } from "../../utils/ApiClient";
import { fileDataSkeletonGenerator } from "../../data/skeletons";
import { Box, Stack, Chip, Divider, Button } from "@mui/material";
import { updateObjectField, isValidInteger } from "../../utils/form";
import { RequestStatusAlert, RequestStatus, defaultStatus } from "../RequestStatus";

import Card from "../Card";
import JsonDropzone from "../JsonDropzone";
import SimpleSelect from "../SimpleSelect";
import DataField from "../fields/DataField";
import FieldDisplay from "../fields/FieldDisplay";

import SettingsIcon from "@mui/icons-material/Settings";
import AnalyticsIcon from "@mui/icons-material/Analytics";

export default function UploadSubmission (props) {
  const apiClient = useApiClient();

  const [jobs, setJobs] = useState([]);
  const [gribs, setGribs] = useState([]);

  const [uploadRequestStatus, setUploadRequestStatus] = useState(defaultStatus());

  const [jobConfiguration, setJobConfiguration] = useState(fileDataSkeletonGenerator("No file"));
  const [submissionResults, setSubmissionResults] = useState(fileDataSkeletonGenerator("No file"));

  const fieldDisplayProps = {
    width: "100%",
    minHeight: "60px"
  }

  const [submissionData, setSubmissionData] = useState({
    jobId: null,
    requestId: null,
    models: [],
    gribFilename: null
  })

  const updateSubmissionData = (field, value) => {
    setSubmissionData(
      updateObjectField(submissionData, field, value)
    )
  }

  const modelChips = () => {
    if (submissionData.models.length === 0) {
      return { value: "N/A" }
    }

    const modelChips = submissionData.models.map((model, index) => {
      return (
        <Chip
          key={index}
          label={model}
          sx={{ mr: 1 }}
          variant="outlined"
        />
      )
    })

    return {
      content: <Box>{modelChips}</Box>
    }
  }

  const uploadSubmission = () => {
    if (submissionData.gribFilename == null) {
      setUploadRequestStatus({
        status: RequestStatus.FAILURE,
        message: "No model start time was selected"
      })

      return
    }

    if (!isValidInteger(submissionData.requestId)) {
      setUploadRequestStatus({
        status: RequestStatus.FAILURE,
        message: "Invalid request id"
      })

      return
    }

    const targetGrib = gribs.filter(
      (g) => g.filename === submissionData.gribFilename
    )[0]

    const requestData = {
      jobId: submissionData.jobId,
      requestId: parseInt(submissionData.requestId),
      modelStartTime: targetGrib.time,
    }

    apiClient.uploadSubmission(
      requestData,
      jobConfiguration,
      submissionResults
    ).then((response) => {
      setUploadRequestStatus({
        status: RequestStatus.SUCCESS,
        message: "Submission uploaded"
      })
    }).catch((error) => {
      handleApiError(setUploadRequestStatus, error)
    })
  }

  useEffect(() => {
    apiClient.jobs(true).then((response) => {
      setJobs(response.data.jobs);
    })

    apiClient.gribs().then((response) => {
      setGribs(response.data.gribs.reverse())
    })
  }, []);

  return (
    <Box sx={props.sx}>
      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={uploadRequestStatus.status}
        message={uploadRequestStatus.message}
      />

      <Card title={"Upload Full Submission"}>
        <Stack spacing={2} direction="row">
          <DataField
            fullWidth
            name={"Request ID"}
            helperText={"The ID of the request returned by the weather model API"}
            value={submissionData.requestId}
            editing={true}
            onChange={(event) => {
              updateSubmissionData("requestId", event.target.value)
            }}
          />

          <SimpleSelect
            fullWidth
            label={"Job"}
            value={submissionData.jobId}
            helperText={"The job this submission should be added to"}
            onChange={(selectedJobId) => {
              updateSubmissionData("jobId", selectedJobId)
            }}
            options={jobs.map((job) => {
              return {
                label: `${job.name} (${job.id})`,
                value: job.id
              }
            })}
          />

          <SimpleSelect
            fullWidth
            label={"Model Start Time"}
            value={submissionData.gribFilename}
            helperText={"The time of the model used for the submission. If two models were used pick one"}
            onChange={(selectedGribFilename) => {
              updateSubmissionData("gribFilename", selectedGribFilename)
            }}
            options={gribs.map((grib) => {
              return {
                label: `${grib.time} (${grib.type})`,
                value: grib.filename
              }
            })}
          />
        </Stack>

        <Divider sx={{ mt: 2 }} />

        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <FieldDisplay
              name={"Job Configuration Filename"}
              value={jobConfiguration.filename}
              sx={fieldDisplayProps}
            />

            <JsonDropzone
              icon={SettingsIcon}
              sx={{ mt: 0, width: "100%" }}
              label="Drag 'n' drop the job json configuration file here or click to browse"
              onDrop={(acceptedFiles) => {
                var reader = new FileReader();

                reader.onload = function (event) {
                  setJobConfiguration({
                    filename: acceptedFiles[0].name,
                    data: JSON.parse(event.target.result)
                  });
                };

                reader.readAsText(acceptedFiles[0]);
              }}
            />
          </Stack>

          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={2} direction="row">
              <FieldDisplay
                name={"Results Filename"}
                value={submissionResults.filename}
                sx={fieldDisplayProps}
              />

              <FieldDisplay
                name={"Models"}
                sx={fieldDisplayProps}
                {...modelChips()}
              />
            </Stack>

            <JsonDropzone
              icon={AnalyticsIcon}
              sx={{ mt: 0, width: "100%" }}
              label="Drag 'n' drop the allTimes.json file here or click to browse"
              onDrop={(acceptedFiles) => {
                var reader = new FileReader();

                reader.onload = function (event) {
                  const allTimesData = JSON.parse(event.target.result);

                  updateSubmissionData("models", Object.keys(allTimesData.finish));
                  setSubmissionResults({
                    filename: acceptedFiles[0].name,
                    data: allTimesData
                  });
                };

                reader.readAsText(acceptedFiles[0]);
              }}
            />
          </Stack>
        </Stack>
      </Card>

      <Box sx={{ mt: 2, textAlign: "right" }}>
        <Button variant="contained" onClick={uploadSubmission}>
          Upload
        </Button>
      </Box>
    </Box>
  )
}