const groupBy = function(data, key) {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function(storage, item) {
    // get the first instance of the key by which we're grouping
    var group = item[key];
    
    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || [];
    
    // add this item to its group within `storage`
    storage[group].push(item);
    
    // return the updated storage to the reduce function, which will then loop through the next 
    return storage; 
  }, {}); // {} is the initial value of the storage
};

/**
 * Returns the run which has the most data points
 */
 const pickResult = (resultA, resultB) => {
  const datapointsResultA = resultA.ncep.length + resultA.ecmwf.length;
  const datapointsResultB = resultB.ncep.length + resultB.ecmwf.length;

  if (datapointsResultA > datapointsResultB) {
    return resultA;
  }

  return resultB;
}

/**
 * Given a list of results returns the deduplicated
 * list sorted (in ascending order) by model timestamp.
 * 
 * Duplicates are results with the same model timestamp.
 * In this case we pick the run with the most datapoints
 * (duplicates should only be results first submitted with
 * a single model and later with both)
 */
const deduplicateResults = (results) => {
  const sortedResults = results.sort((a, b) => {
    return a.modelStartTimeTimestamp - b.modelStartTimeTimestamp
  })

  const resultGroups = groupBy(
    sortedResults,
    "modelStartTimeTimestamp"
  )

  return Object.keys(resultGroups).map((ts) => {
    const group = resultGroups[ts];

    if (group.length === 1) {
      return group[0];
    }

    return pickResult(group[0], group[1]);
  })
}

export {
  groupBy,
  deduplicateResults
}