import { useState, useEffect } from "react";
import { calculateMean } from "../../utils/math";
import { deduplicateResults } from "../../utils/data";
import { formatDateTime } from "../../utils/format";
import { useApiClient } from "../../utils/ApiClient";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RequestStatus, defaultStatus, RequestStatusAlert } from "../../components/RequestStatus";
import { trendlineTrace, spreadIndex, lineTrace, PlotColors, refTimeShape } from "../../utils/charts";
import { Stack, TextField, Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import Plot from "react-plotly.js";

import Card from "../Card";
import SimpleSelect from "../SimpleSelect";

const _ = require("lodash");
const moment = require("moment");

export default function CompareRunsCard (props) {
  const apiClient = useApiClient();

  const [startTime, setStartTime] = useState(null);
  const [startTimeHours, setStartTimeHours] = useState(0);

  const [resultsLimit, setResultsLimit] = useState(20);

  const [customRefTimes, setCustomRefTimes] = useState([]);
  const [customRefTimesEnabled, setCustomRefTimesEnabled] = useState(false);

  const [selectedLine2cross, setSelectedLine2cross] = useState("finish");
  const [lines2cross, setLines2cross] = useState([{
    label: "finish",
    value: "finish"
  }]);

  const [plotData, setPlotData] = useState({
    refTime: null,
    startTime: null,
    traces: [],
    tickvals: [],
    ticktext: [],
    supportPlotRange: [0, 100]
  });

  const [dataRequestStatus, setDataRequestStatus] = useState(defaultStatus());

  useEffect(() => {
    apiClient.lines2cross().then((response) => {
      setLines2cross(response.data.lines2cross.map((name) => {
        return {
          label: name,
          value: name
        }
      }));

      setDataRequestStatus(defaultStatus());
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load lines to cross: ${message}`
      })
    })

    apiClient.customRefTimes().then((response) => {
      setCustomRefTimes(response.data.refTimes);
    })
  }, [])

  const fullStartTime = () => {
    return moment(startTime).add(startTimeHours, "hours");
  }

  const compare = () => {
    const jobId = props.job ? props.job.id : null

    apiClient.compare(
      fullStartTime(),
      selectedLine2cross,
      jobId
    ).then((response) => {
      const sortedRuns = response.data.runs.sort((a, b) => {
        return a.modelStartTimeTimestamp - b.modelStartTimeTimestamp
      })

      const means = {
        ncep: { x: [], y: [] },
        ecmwf: { x: [], y: [] }
      }

      const ncep = {
        y: [],
        x: [],
        type: "box",
        name: "ncep",
        boxpoints: "all",
        marker: { color: PlotColors.ncep.box }
      }

      const ecmwf = {
        y: [],
        x: [],
        type: "box",
        name: "ecmwf",
        boxpoints: "all",
        marker: { color: PlotColors.ecmwf.box }
      }

      const spreads = {
        ncep: { x: [], y: [] },
        ecmwf: { x: [], y: [] }
      }

      const ensemblesPercentage = {
        ncep: { x: [], y: [] },
        ecmwf: { x: [], y: [] }
      }

      const tickvals = [];
      const ticktext = [];

      let deduplicatedSortedRuns = deduplicateResults(sortedRuns);

      if (deduplicatedSortedRuns.length > resultsLimit) {
        deduplicatedSortedRuns = deduplicatedSortedRuns.slice(
          deduplicatedSortedRuns.length - resultsLimit
        )
      }

      // Used to calculate the support plot x range
      const runTimestamps = deduplicatedSortedRuns.map(x => x.modelStartTimeTimestamp);

      deduplicatedSortedRuns.forEach((run) => {
        const timeLabel = formatDateTime(moment(run.modelStartTime), true)

        const ncepSpread = spreadIndex(run.ncep).toFixed(2);
        const ecmwfSpread = spreadIndex(run.ecmwf).toFixed(2);

        const label = moment(run.modelStartTime).format("X")
        const textLabel = `#${run.requestId} ${timeLabel}`

        spreads.ncep.x.push(label);
        spreads.ecmwf.x.push(label);
        spreads.ncep.y.push(ncepSpread * 100);
        spreads.ecmwf.y.push(ecmwfSpread * 100);

        ensemblesPercentage.ncep.x.push(label);
        ensemblesPercentage.ecmwf.x.push(label);
        ensemblesPercentage.ncep.y.push((run.ncep.length / 30) * 100);
        ensemblesPercentage.ecmwf.y.push((run.ecmwf.length / 50) * 100);

        tickvals.push(label);
        ticktext.push(textLabel);

        ncep.y = ncep.y.concat(run.ncep)
        ncep.x = ncep.x.concat(Array(run.ncep.length).fill(label))

        ecmwf.y = ecmwf.y.concat(run.ecmwf)
        ecmwf.x = ecmwf.x.concat(Array(run.ecmwf.length).fill(label))

        // Create trendline data, skipping the submissions
        // for which the mean is null, otherwise plotly
        // may misbehave
        const ncepMean = calculateMean(run.ncep)
        const ecmwfMean = calculateMean(run.ecmwf)

        if (ncepMean != null) {
          means.ncep.x.push(label)
          means.ncep.y.push(ncepMean)
        }

        if (ecmwfMean != null) {
          means.ecmwf.x.push(label)
          means.ecmwf.y.push(ecmwfMean)
        }
      })

      const secondsInDay = 86400;

      setPlotData({
        refTime: response.data.refTime,
        startTime: fullStartTime(),
        traces: [
          ncep,
          ecmwf,
          trendlineTrace(means.ncep.x, means.ncep.y, "ncep"),
          trendlineTrace(means.ecmwf.x, means.ecmwf.y, "ecmwf"),
          lineTrace(spreads.ncep.x, spreads.ncep.y, "ncep spread", "x2", "y2", PlotColors.ncep.box),
          lineTrace(spreads.ecmwf.x, spreads.ecmwf.y, "ecmwf spread", "x2", "y2", PlotColors.ecmwf.box),
          lineTrace(ensemblesPercentage.ncep.x, ensemblesPercentage.ncep.y, "ncep ensembles", "x2", "y3", PlotColors.ncep.box, true),
          lineTrace(ensemblesPercentage.ecmwf.x, ensemblesPercentage.ecmwf.y, "ecmwf ensembles", "x2", "y3", PlotColors.ecmwf.box, true)
        ],
        tickvals: tickvals,
        ticktext: ticktext,
        supportPlotRange: [
          // Magic values that *just* work
          runTimestamps[0] - secondsInDay / 3.25,
          runTimestamps.pop() + secondsInDay / 3.25
        ]
      })

      setDataRequestStatus(defaultStatus());
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load comparison plot: ${message}`
      })
    })
  }

  const line2crossCustomRefTimes = customRefTimesEnabled ? customRefTimes
    .filter((r) => r.name === selectedLine2cross)
    .map((r) => refTimeShape(r.timeInDays)) : []

  return (
    <Card title="Compare Runs" sx={{ maxHeight: "1024px", ...props.sx ?? {} }}>
      <Stack direction="row" spacing={2} sx={ plotData.traces.length > 0 ? { mb: 2 } : {}}>
        <SimpleSelect
          fullWidth
          label={"Results Limit"}
          value={resultsLimit}
          onChange={(value) => setResultsLimit(value)}
          options={_.range(5, 21).map(i => {
            return { label: i, value: i }
          })}
        />

        <SimpleSelect
          fullWidth
          value={selectedLine2cross}
          options={lines2cross}
          label={"Line to Cross"}
          onChange={(value) => setSelectedLine2cross(value)}
        />

        <DatePicker
          label="Start Date (UTC)"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={startTime}
          onChange={(value) => {
            setStartTime(value)
          }}
        />

        <SimpleSelect
          sx={{ width: "400px" }}
          label={"Hour"}
          value={startTimeHours}
          onChange={(value) => setStartTimeHours(value)}
          options={_.range(0, 24).map(i => {
            return { label: i, value: i }
          })}
        />

        <FormGroup>
          <FormControlLabel
            checked={customRefTimesEnabled}
            control={<Checkbox />}
            label="Custom refTimes"
            sx={{ mt: "auto", mb: "auto" }}
            onChange={(event) => {
              setCustomRefTimesEnabled(event.target.checked)
            }}
          />
        </FormGroup>

        <Button onClick={compare}>
          Compare
        </Button>
      </Stack>

      <RequestStatusAlert
        sx={{ mb: 0, mt: 2 }}
        status={dataRequestStatus.status}
        message={dataRequestStatus.message}
      />

      {plotData.traces.length > 0 && (
        <Plot
          style={{ width: "100%", height: "100%", minHeight: "768px" }}
          data={plotData.traces}
          layout={{
            autosize: true,
            title: `${plotData.startTime.toISOString()} runs comparison`,
            grid: { rows: 2, columns: 1, pattern: "independent" },
            boxmode: "group",
            xaxis: {
              tickvals: plotData.tickvals,
              ticktext: plotData.ticktext,
              automargin: true,
              zeroline: false
            },
            yaxis: {
              title: {
                text: "Sail Time [days]"
              },
              domain: [0.575, 1],
              zeroline: false
            },
            yaxis2: {
              title: {
                text: "Spread Index (%)"
              },
              range: [-10, 110],
              domain: [0, 0.425],
              zeroline: false
            },
            yaxis3: {
              side: "right",
              range: [-10, 110],
              overlaying: "y2",
              title: "Ensembles Completed (%)",
              zeroline: false
            },
            xaxis2: {
              zeroline: false,
              automargin: true,
              tickvals: plotData.tickvals,
              ticktext: plotData.ticktext,
              range: plotData.supportPlotRange
            },
            showlegend: true,
            shapes: plotData.refTime ? [
              refTimeShape(plotData.refTime),
              ...line2crossCustomRefTimes
            ] : [...line2crossCustomRefTimes]
          }}
        />
      )}
    </Card>
  )
}