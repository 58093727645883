import { Box, Stack, Divider } from "@mui/material";

import Field from "./Field";

export default function JobSummary (props) {
  const submission = props.job.submission;
  const route = props.job.payload.features.filter((feature) => {
    return feature.properties.type === "Route";
  }).pop();

  if (route == null) {
    return <Box></Box>
  }

  const filename = props.filename;

  return (
    <Stack direction={"row"} spacing={2} sx={props.sx}>
      {filename && (
        <Field
          name={"Filename"}
          value={filename}
        />
      )}

      {filename && <Divider orientation="vertical" flexItem />}
      
      <Field
        name={"Start Time (UTC)"}
        value={route.properties.startTime}
      />

      <Divider orientation="vertical" flexItem />

      <Field
        name={"Model"}
        value={route.properties.model}
      />

      <Divider orientation="vertical" flexItem />

      <Field
        name={"BS Hours"}
        value={route.properties.BS_hours}
      />

      <Divider orientation="vertical" flexItem />

      <Field
        name={"BS Frequency"}
        value={route.properties.BS_freq}
      />

      {props.job.submitted && (
        <Divider orientation="vertical" flexItem />
      )}

      {/* Submission information */}
      {props.job.submitted && (
        <Field
          name={"Submission ID"}
          value={submission.id}
        />
      )}

      {props.job.submitted && (
        <Divider orientation="vertical" flexItem />
      )}

      {props.job.submitted && (
        <Field
          name={"Submission Model"}
          value={submission.model}
        />
      )}
    </Stack>
  )
}