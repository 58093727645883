import { createContext, useContext, useState } from "react";


const AuthContext = createContext(null);

const AuthProvider = ({ user, children }) => {
  const [currentUser, setCurrentUser] = useState(user);

  const updateUser = (newUser) => {
    setCurrentUser(newUser);
    localStorage.user = JSON.stringify(newUser);
  }

  return (
    <AuthContext.Provider value={{ currentUser, updateUser }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext);
const getLocalUser = () => {
  const defaultUser = { token: null }

  if (localStorage.getItem("user") == null) {
    return defaultUser
  }

  return JSON.parse(localStorage.getItem("user"));
}

export {
  AuthProvider,
  useAuth,
  getLocalUser
};