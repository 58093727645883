import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useApiClient } from "../utils/ApiClient";
import { RequestStatus, defaultStatus, RequestStatusAlert } from "../components/RequestStatus";

import ViewHeader from "../components/ViewHeader";
import SingleRunCard from "../components/visualizations/SingleRunCard";
import CompareRunsCard from "../components/visualizations/CompareRunsCard";
import CompareFirstStepsCard from "../components/visualizations/CompareFirstStepsCard";
import InsightsConfigurationCard from "../components/visualizations/InsightsConfigurationCard";

export default function Insights () {
  const apiClient = useApiClient();

  // Dictionary that maps a date (YYYY-MM-DD) to a NAO value
  const [naoValues, setNaoValues] = useState({});
  const [dataRequestStatus, setDataRequestStatus] = useState(defaultStatus());

  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    apiClient.nao().then((response) => {
      setNaoValues(response.data);
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load NAO values: ${message}`
      })
    })
  }, [])

  return (
    <Box>
      <ViewHeader title={"Insights"} />

      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={dataRequestStatus.status}
        message={dataRequestStatus.message}
      />

      <InsightsConfigurationCard
        sx={{ mb: 2 }}
        job={selectedJob}
        onJobSelected={(job) => {
          setSelectedJob(job)
        }}
      />

      <CompareRunsCard
        sx={{ mb: 2 }}
        job={selectedJob}
      />

      <SingleRunCard
        sx={{ mb: 2 }}
        job={selectedJob}
      />

      <CompareFirstStepsCard
        sx={{ mb: 2 }}
        job={selectedJob}
        naoValues={naoValues}
      />
    </Box>
  );
}