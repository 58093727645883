import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from "@mui/material";

import { NavLink } from "react-router-dom";

export default function DrawerItem (props) {
  return (
    <ListItem disablePadding sx={props.sx}>
      <NavLink
        to={props.to}
        className={({ isActive }) => isActive ? "drawer-link-active drawer-link" : "drawer-link"}
      >
        <ListItemButton
          sx={{
            borderRadius: 3,
            marginBottom: 1
          }}
        >
          {props.icon && (
            <ListItemIcon>
              {props.icon}
            </ListItemIcon>
          )}

          <ListItemText primary={props.name} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  )
}