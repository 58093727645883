import { useState, useEffect } from "react";
import { useApiClient } from "../utils/ApiClient";
import { Link, Outlet, useNavigate } from "react-router-dom";

import {
  Box,
  List,
  Drawer,
  Button,
  Typography,
  Container,
  Stack,
  Divider
} from "@mui/material";

import AppleSwitch from "../components/AppleSwitch";
import DrawerItem from "../components/DrawerItem";
import DrawerHeader from "../components/DrawerHeader";

import DnsIcon from "@mui/icons-material/Dns";
import InsightsIcon from "@mui/icons-material/Insights";
import SettingsIcon from "@mui/icons-material/Settings";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function App() {
  const drawerWidth = 320;
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const [botEnabled, setBotEnabled] = useState(false);

  const logout = () => {
    localStorage.user = null;
    navigate("/login");
  }

  const updateBotStatus = () => {
    apiClient.updateBotStatus(!botEnabled).then((response) => {
      setBotEnabled(!botEnabled);
    });
  }

  useEffect(() => {
    // If the user is not logged in redirect to the login screen
    if (localStorage.user == null) {
      navigate("/login");
    }

    // Check if the token has expired. If so redirect the user to /login
    apiClient.healthCheck().then((response) => {}).catch((error) => {
      if (error.response && [401, 422].includes(error.response.status)) {
        // Unathorized, thus logout the user
        logout()
      }
    });

    apiClient.botStatus().then((enabled) => {
      setBotEnabled(enabled);
    });
  }, [])

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        id="app-drawer"
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          }
        }}
        variant="permanent"
        anchor="left"
        open={true}
      >
        <DrawerHeader
          sx={{
            marginTop: 1,
            marginLeft: 0.5
          }}
        >
          <Link to="/" className={"drawer-link"}>
            <Typography variant="h6" component="div">
              Weather Job Console
            </Typography>
          </Link>
        </DrawerHeader>

        <Stack direction={"row"} sx={{ ml: 2, mb: 2 }} spacing={2}>
          <AppleSwitch
            checked={botEnabled}
            onChange={updateBotStatus}
          />

          <Typography variant="subtitle1" component="div">
            {botEnabled ? "Bot Enabled" : "Bot Disabled"}
          </Typography>
        </Stack>

        <Box sx={{ ml: 2, mr: 2, mb: 1 }}>
          <Divider />
        </Box>

        <List
          sx={{
            margin: 2,
            marginTop: 0
          }
        }>
          <DrawerItem to={"/jobs"} icon={<TaskAltIcon />} name={"Jobs"} />
          <DrawerItem to={"/insights"} icon={<InsightsIcon />} name={"Insights"} />
          <DrawerItem to={"/upload-submission-results"} icon={<FileUploadIcon />} name={"Upload Submission"} />
          <DrawerItem to={"/status"} icon={<DnsIcon />} name={"System Status"} />
          <DrawerItem to={"/settings"} icon={<SettingsIcon />} name={"Settings"} />
        </List>

        <Box sx={{ flexGrow: 1 }}></Box>

        <Button
          color="error"
          onClick={logout}
          sx={{ mb: 2, ml: 2, mr: 2 }}
        >
          Logout
        </Button>
      </Drawer>

      <Box
        component="main"
        id="app-content"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: 3,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Container maxWidth={null}>
          <Outlet/>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
