import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Alert, TextField, Grid } from "@mui/material";
import { useApiClient } from "../utils/ApiClient";

import SyntaxHighlighter from "react-syntax-highlighter";
import { anOldHope } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { jobSkeleton } from "../data/skeletons";

import Card from "../components/Card";
import JobSummary from "../components/JobSummary";
import DetailsHeader from "../components/DetailsHeader";
import JsonDropzone from "../components/JsonDropzone";

import { handleApiError } from "../utils/errors";
import { jobSubmissionColumns } from "../data/columns";
import { RequestStatusAlert, RequestStatus, defaultStatus } from "../components/RequestStatus";

export default function BodyDetails () {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const [editing, setEditing] = useState(false);

  const [job, setJob] = useState(structuredClone(jobSkeleton));
  const [frozenJob, setFrozenJob] = useState(structuredClone(jobSkeleton));

  const [updateRequestStatus, setUpdateRequestStatus] = useState(defaultStatus());

  const [error, setError] = useState("");

  const deleteJob = () => {
    apiClient.deleteJob(id).then((response) => {
      // FIXME: Job does not disappear from the table
      navigate("/jobs")
    })
  }

  const updateJob = () => {
    apiClient.updateJob(id, job.name, job.payload).then((response) => {
      setEditing(false);
      setUpdateRequestStatus({
        status: RequestStatus.SUCCESS,
        message: "Job Updated"
      })
    }).catch((error) => {
      handleApiError(setUpdateRequestStatus, error)
    })
  }

  const updateJobEnabledStatus = (event) => {
    apiClient.updateJobEnabledStatus(id, event.target.checked).then((response) => {
      const updatedJob = {
        ...job,
        enabled: response.data.enabled
      }

      setJob(updatedJob)
      setFrozenJob(updatedJob)
    }).catch((error) => {
      handleApiError(setUpdateRequestStatus, error)
    })
  }

  useEffect(() => {
    setError("");

    apiClient.job(id).then((body) => {
      setJob(body);
    }).catch((error) => {
      if (error.response) {
        setError(error.response.data.message);
      }
    })
  }, [id]);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Error loading job: {error}
        </Alert>
      )}

      {!error && (
        <Box>
          <DetailsHeader
            editing={editing}
            title={job.name}
            enabled={job.enabled}
            subtitle={`Details for job #${job.id}`}
            enableEditing={() => {
              setEditing(true);
              setFrozenJob(job);
            }}
            onCancel={() => {
              setEditing(false);
              setJob(frozenJob);
            }}
            onSave={updateJob}
            onDelete={deleteJob}
            onEnabledStatusChanged={updateJobEnabledStatus}
          />

          <RequestStatusAlert
            sx={{ mb: 2 }}
            status={updateRequestStatus.status}
            message={updateRequestStatus.message}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <JobSummary job={job} />

                {!editing && (
                  <SyntaxHighlighter language="json" style={anOldHope} className="config-code-block">
                    {JSON.stringify(job.payload, null, 2)}
                  </SyntaxHighlighter>
                )}

                {editing && (
                  <Box>
                    <TextField
                      fullWidth
                      sx={{ mt: 2 }}
                      variant="outlined"
                      label="Job Name"
                      value={job.name}
                      onChange={(event) => {
                        setJob({
                          ...job,
                          name: event.target.value
                        })
                      }}
                    />
                    <JsonDropzone
                      label="Drag 'n' drop the project file here or click to browse"
                      onDrop={(acceptedFiles) => {
                        var reader = new FileReader();

                        reader.onload = function (event) {
                          setJob({
                            ...job,
                            payload: JSON.parse(event.target.result)
                          })
                        };

                        reader.readAsText(acceptedFiles[0]);
                      }}
                    />
                  </Box>
                )}
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ height: 800, width: "100%", mb: 2 }}>
                <DataGrid
                  columns={jobSubmissionColumns}
                  rows={job.submissions}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  disableSelectionOnClick
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}