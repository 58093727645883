import { Box } from "@mui/material";

import ViewHeader from "../components/ViewHeader";
import UploadSubmission from "../components/upload/UploadSubmission"
import UploadSubmissionResult from "../components/upload/UploadSubmissionResult"

export default function UploadResults () {
  return (
    <Box>
      <ViewHeader title={"Upload Submissions"} />

      <UploadSubmission sx={{ mb: 2 }} />
      <UploadSubmissionResult />
    </Box>
  )
}