import axios from "axios";
import moment from "moment-timezone";

import { getLocalUser } from "./auth.context";

export default class ApiClient {
  constructor () {
    const user = getLocalUser();
    const headers = user ? {
      Authorization: `Bearer ${user.token}`
    } : {}

    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ROOT,
      headers: headers
    });
  }

  login (email, password) {
    const payload = {
      email: email,
      password: password
    };

    return this.client.post("/login", payload).then((response) => {
      return {
        token: response.data.token
      };
    });
  }

  healthCheck () {
    return this.client.get("/health-check");
  }

  jobs (includeDeleted) {
    return this.client.get("/jobs/", {
      params: { includeDeleted: includeDeleted ? 1 : 0 }
    });
  }

  job (id) {
    return this.client.get(`/jobs/${id}`).then((response) => {
      const body = response.data;
      body.payload = JSON.parse(atob(body.payload));

      return body
    });
  }

  saveJob (name, configuration) {
    const payload = {
      name: name,
      configuration: btoa(JSON.stringify(configuration))
    }

    return this.client.post("/jobs/", payload);
  }

  updateJob (id, name, configuration) {
    const payload = {
      name: name,
      configuration: btoa(JSON.stringify(configuration))
    }

    return this.client.put(`/jobs/${id}`, payload);
  }

  updateJobEnabledStatus (id, enabledStatus) {
    return this.client.post(`/jobs/${id}/enabled`, {
      enabled: enabledStatus
    });
  }

  deleteJob (id) {
    return this.client.delete(`/jobs/${id}`);
  }

  settings () {
    return this.client.get("/settings/").then((response) => {
      const settings = response.data;

      settings.morningCheck.start = moment(settings.morningCheck.start);
      settings.morningCheck.end = moment(settings.morningCheck.end);

      settings.eveningCheck.start = moment(settings.eveningCheck.start);
      settings.eveningCheck.end = moment(settings.eveningCheck.end);

      return settings;
    });
  }

  updateSettings (settings) {
    const payload = {
      notificationEmails: settings.notificationEmails,
      checkFrequency: parseInt(settings.checkFrequency),
      sendSingleAfterMinutes: parseInt(settings.sendSingleAfterMinutes),
      startTimeOffset: parseInt(settings.startTimeOffset),
      morningCheck: {
        start: settings.morningCheck.start.toISOString(),
        end: settings.morningCheck.end.toISOString()
      },
      eveningCheck: {
        start: settings.eveningCheck.start.toISOString(),
        end: settings.eveningCheck.end.toISOString()
      }
    };

    return this.client.put("/settings/", payload);
  }

  updateBotStatus (enabled) {
    if (enabled) {
      return this.client.post("/settings/enable");
    }

    return this.client.post("/settings/disable");
  }

  botStatus () {
    return this.client.get("/settings/status").then((response) => {
      return response.data.enabled;
    });
  }

  nao () {
    return this.client.get("/nao");
  }

  systemStatus () {
    return this.client.get("/system-status");
  }

  /** Submission results routes */
  submissionResults () {
    return this.client.get("/results/")
  }

  submissionResult (resultId) {
    return this.client.get(`/results/${resultId}`)
  }

  compare (startTime, line2cross, jobId) {
    return this.client.post("/results/compare", {
      line2cross: line2cross,
      startTime: startTime.toISOString(),
      jobId: jobId
    })
  }

  /**
   * 
   * @param {object} timeframe: { start: moment/date, end: moment/date }
   * @param {string} line2cross
   */
  compareFirstSteps (timeframe, line2cross, jobId) {
    return this.client.post("/results/compare-first-steps", {
      jobId: jobId,
      line2cross: line2cross,
      timeframe: {
        start: timeframe.start.toISOString(),
        end: timeframe.end.toISOString()
      }
    })
  }

  /**
   * Returns the ids of existing job sumbissions
   */
  validSubmissions () {
    return this.client.get("/results/valid-submissions")
  }

  lines2cross () {
    return this.client.get("/results/lines2cross")
  }

  gribs () {
    return this.client.get("/gribs/")
  }

  uploadSubmission (submissionData, jobConfiguration, submissionResults) {
    return this.client.post("/results/upload-submission", {
      jobId: submissionData.jobId,
      requestId: submissionData.requestId,
      modelStartTime: submissionData.modelStartTime,
      // Job data files
      jobConfiguration: jobConfiguration.data,
      submissionResults: submissionResults.data
    })
  }

  uploadSubmissionResults (submissionId, results) {
    return this.client.post("/results/upload-results", {
      submissionId: submissionId,
      results: results
    })
  }

  /**
   * Custom refTime routes
   */
  customRefTimes () {
    return this.client.get("/ref-times")
  }

  saveCustomRefTime (refTime) {
    return this.client.post("/ref-times", refTime)
  }

  deleteCustomRefTime (refTimeId) {
    return this.client.delete(`/ref-times/${refTimeId}`)
  }
}

export const useApiClient = () => new ApiClient();
