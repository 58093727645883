import { Box, TextField } from "@mui/material";

import FieldDisplay from "./FieldDisplay";

export default function DataField (props) {
  const hasValidValue = props.value != null && props.value !== ""

  const value = !hasValidValue ? "N/A" : props.value;
  const sx = props.fullWidth ? { width: "100%" } : {};

  const textFieldValue = !hasValidValue ? "" : props.value;

  return (
    <Box sx={sx}>
      {!props.editing && (
        <FieldDisplay
          value={value}
          name={props.name}
          helperText={props.helperText}
          tooltipText={props.tooltipText}
        />
      )}

      {props.editing && (
        <TextField
          fullWidth
          multiline={props.multiline}
          label={props.name}
          variant="outlined"
          value={textFieldValue}
          onChange={props.onChange}
          helperText={props.helperText}
        />
      )}
    </Box>
  )
}