import { useEffect, useState } from "react";
import { useApiClient } from "../../utils/ApiClient";
import { fileDataSkeletonGenerator } from "../../data/skeletons";
import { Box, Button, MenuItem, Typography, Stack } from "@mui/material";
import { RequestStatusAlert, RequestStatus, defaultStatus } from "../RequestStatus";

import Card from "../Card";
import Field from "../Field";
import SimpleSelect from "../SimpleSelect";
import JsonDropzone from "../JsonDropzone";

import CheckIcon from "@mui/icons-material/CheckCircle";

export default function UploadSubmissionResult (props) {
  const apiClient = useApiClient();

  const [submissionId, setSubmissionId] = useState(null);
  const [submissionIdsOptions, setSubmissionIdsOptions] = useState([]);
  const [submissionResults, setSubmissionResults] = useState(fileDataSkeletonGenerator());
  
  const [uploadRequestStatus, setUploadRequestStatus] = useState(defaultStatus());

  useEffect(() => {
    apiClient.validSubmissions().then(response => {
      setSubmissionIdsOptions(response.data.submissions.map(s => {
        return {
          value: s.id,
          hasResults: s.hasResults,
          label: `Run #${s.requestId} - Job ${s.job}`
        }
      }))
    })
  }, [])

  const uploadResults = () => {
    if (Object.keys(submissionResults.data).length === 0) {
      setUploadRequestStatus({
        status: RequestStatus.FAILURE,
        message: "No file selected"
      });

      return;
    }

    apiClient.uploadSubmissionResults(
      submissionId,
      submissionResults.data
    ).then((response) => {
      setUploadRequestStatus({
        status: RequestStatus.SUCCESS,
        message: "Results Uploaded"
      })
    }).catch((error) => {
      if (error.response) {
        setUploadRequestStatus({
          status: RequestStatus.FAILURE,
          message: error.response.data.message
        });
      } else {
        setUploadRequestStatus({
          status: RequestStatus.FAILURE,
          message: error.toString()
        });
      }
    })
  }

  return (
    <Box sx={props.sx}>
      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={uploadRequestStatus.status}
        message={uploadRequestStatus.message}
      />

      <Card title={"Upload Submission Result"}>
        <SimpleSelect
          fullWidth
          value={submissionId}
          options={submissionIdsOptions}
          label={"Submission ID"}
          onChange={(value) => setSubmissionId(value)}
          getItem={(option) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                <Stack direction="row" spacing={1}>
                  {option.hasResults && <CheckIcon color="success" />}
                  <Typography>{option.label}</Typography>
                </Stack>
              </MenuItem>
            )
          }}
        />

        {submissionResults.filename && (
          <Field
            sx={{ mt: 1 }}
            name={"Filename"}
            value={submissionResults.filename}
          />
        )}

        <JsonDropzone
          label="Drag 'n' drop the allTimes.json file here or click to browse"
          onDrop={(acceptedFiles) => {
            var reader = new FileReader();

            reader.onload = function (event) {
              setSubmissionResults({
                filename: acceptedFiles[0].name,
                data: JSON.parse(event.target.result)
              });
            };

            reader.readAsText(acceptedFiles[0]);
          }}
        />
      </Card>

      <Box sx={{ mt: 2, textAlign: "right" }}>
        <Button variant="contained" onClick={uploadResults}>
          Upload
        </Button>
      </Box>
    </Box>
  )
}