const formatDate = (date, separator) => {
  if (separator == null) separator = "/";
  return date.format(`YYYY${separator}MM${separator}DD`);
}

const formatDateTime = (date, short) => {
  if (short) return date.format("DD/MM/YY@HH");

  return date.format("YYYY/MM/DD HH:mm:ss");
}

export {
  formatDate,
  formatDateTime
}