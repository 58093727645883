import { Paper, Box } from "@mui/material";

import CardLabel from "./CardLabel";

export default function Card (props) {
  return (
    <Box sx={props.sx}>
      {props.title && <CardLabel text={props.title} sx={{ mb: 1 }} />}

      <Paper
        elevation={0}
        sx={{
          padding: 2,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "border.main",
          "& .MuiAlert-message": {
            paddingBottom: 0
          }
        }}
      >
        {props.children}
      </Paper>
    </Box>
  )
}