import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText
} from "@mui/material";

export default function SimpleSelect(props) {
  const defaultItemGetter = (option) => {
    return (
      <MenuItem
        key={option.value}
        value={option.value}
      >
        {option.label}
      </MenuItem>
    )
  }

  const value = (props.value === "" || props.value == null) ? "" : props.value;
  const itemGetter = props.getItem ? props.getItem : defaultItemGetter;

  return (
    <FormControl
      sx={props.sx}
      fullWidth={props.fullWidth}
      error={props.error}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={value}
        label={props.label}
        onChange={(event) => {
          props.onChange(event.target.value)
        }}
      >
        {props.options.map(itemGetter)}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}