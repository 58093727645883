import { calculateMean } from "./math";

const d3 = require("d3-array");

const PlotColors = {
  refTime: "#e74c3c",
  ncep: {
    box: "#71b7e6",
    trendline: "#246a99",
    ensemble: "#246a99"
  },
  ecmwf: {
    box: "#45b39d",
    trendline: "#0f705d",
    ensemble: "#0f705d"
  }
}

const refTimeShape = (y) => {
  return {
    type: "line",
    x0: 0,
    x1: 1,
    y0: y,
    y1: y,
    xref: "paper",
    yref: "y",
    line: {
      color: PlotColors.refTime,
      width: 2,
      dash: "dash"
    }
  }
}

/**
 * Calculates the spread index, aka
 * (max - min) / median for a given
 * array of results.
 *
 * @param {array} data
 * @returns {float} the spread index
 */
const spreadIndex = (data) => {
  if (data.length === 0) return 0;

  data.sort();

  const q1 = d3.quantile(data, 0.25);
  const q3 = d3.quantile(data, 0.75);

  const iqr = q3 - q1;
  const median = d3.quantile(data, 0.5);

  const minimum = q1 - 1.5 * iqr;
  const maximum = q3 + 1.5 * iqr;

  return (maximum - minimum) / median;
}

const lineTrace = (x, y, traceName, xaxis, yaxis, color, useDashes) => {
  return {
    x: x,
    y: y,
    xaxis: xaxis,
    yaxis: yaxis,
    type: "scatter",
    mode: "lines",
    name: traceName,
    line: {
      color: color,
      ...(useDashes ? { dash: "dash" } : {})
    }
  }
}

const trendlineTrace = (x, y, traceName) => {
  return {
    x: x,
    y: y,
    type: "scatter",
    mode: "lines+markers",
    name: `${traceName} mean`,
    marker: { size: 8 },
    line: { color: PlotColors[traceName].trendline }
  }
}

const boxPlotTrace = (x, y, traceName) => {
  return {
    y: y,
    x: x,
    type: "box",
    boxpoints: "all",
    name: traceName,
    marker: {
      color: PlotColors[traceName].box
    }
  }
}

const tracesFromResults = (results, traceName) => {
  let y = [];
  let x = [];

  // How many individual model executions were completed?
  const ensemble = { x: [], y: []}
  const spreads = { x: [], y: [] }
  const means = { x: [], y: [] }

  const maximumEnsembles = traceName === "ncep" ? 30 : 50;

  Object.keys(results).forEach(key => {
    const stepData = results[key];

    y = y.concat(stepData);
    x = x.concat(Array(stepData.length).fill(parseInt(key)));

    means.x.push(parseInt(key));
    means.y.push(calculateMean(stepData));

    ensemble.x.push(parseInt(key));
    ensemble.y.push((stepData.length / maximumEnsembles) * 100);

    spreads.x.push(parseInt(key));
    spreads.y.push(spreadIndex(stepData) * 100);
  })

  return [
    {
      ...boxPlotTrace(x, y, traceName),
      tickvals: Object.keys(results).map(x => parseInt(x))
    },
    trendlineTrace(means.x, means.y, traceName),
    lineTrace(spreads.x, spreads.y, `${traceName} spread`, "x2", "y2", PlotColors[traceName].box),
    lineTrace(ensemble.x, ensemble.y, `${traceName} ensembles`, "x2", "y3", PlotColors[traceName].box, true)
  ]
}

const zerolineShape = (xaxis, yaxis) => {
  const trace = refTimeShape(0);

  trace.yref = yaxis;
  trace.xaxis = xaxis;
  trace.yaxis = yaxis;

  return trace;
}

export {
  lineTrace,
  PlotColors,
  spreadIndex,
  refTimeShape,
  boxPlotTrace,
  zerolineShape,
  trendlineTrace,
  tracesFromResults
};
