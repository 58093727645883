import { Box, Typography, IconButton, Stack } from "@mui/material";

export default function ViewHeader (props) {
  return (
    <Stack direction="row" sx={{ marginBottom: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1 }}
        >
          {props.title}
        </Typography>

        {props.subtitle && (
          <Typography
            variant="subtitle1"
            component="div"
          >
            {props.subtitle}
          </Typography>
        )}
      </Box>

      {props.actionIcon && (
        <IconButton
          size="large"
          aria-label="create job"
          onClick={props.onActionClick}
          sx={{
            mt: "auto",
            mb: "auto"
          }}
        >
          {props.actionIcon}
        </IconButton>
      )}
    </Stack>
  )
}