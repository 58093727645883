function intFormatter (params) {
  return params.value;
}

function percentageFormatter (params) {
  const value = parseInt(params.value * 100);

  return `${value}%`;
}

export {
  intFormatter,
  percentageFormatter
};