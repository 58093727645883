import App from "./views/App";
import Jobs from "./views/Jobs";
import Login from "./views/Login";
import Insights from "./views/Insights";
import Settings from "./views/Settings";
import CreateJob from "./views/CreateJob";
import JobDetails from "./views/JobDetails";
import SystemStatus from "./views/SystemStatus";
import UploadResults from "./views/UploadResults";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />}>
          <Route path="/jobs" element={<Jobs />}>
            <Route path="/jobs/:id" element={<JobDetails />} />
          </Route>

          <Route path="/jobs/create" element={<CreateJob />} />
          <Route path="/upload-submission-results" element={<UploadResults />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/status" element={<SystemStatus />} />

          <Route
            path="/"
            element={<Navigate to="/jobs" replace />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes;