import { useState } from "react";
import { Stack, Chip, Box, TextField, Button } from "@mui/material";

export default function EmailsField (props) {
  const [currentEmail, setCurrentEmail] = useState("");
 
  const emailChips = [...props.emails].map((email) => {
    return (
      <Chip
        key={email}
        label={email}
        sx={{ mr: 1 }}
        variant="outlined"
        onDelete={() => {
          props.onDelete(email);
        }}
      />
    )
  })

  const addEmail = () => {
    props.onAdd(currentEmail);
    setCurrentEmail("");
  }

  return (
    <Box>
      {[...emailChips].length > 0 && (
        <Stack direction="row" sx={{ mb: 2 }}>
          {emailChips}
        </Stack>
      )}

      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          id="email-field"
          variant="outlined"
          label="Email"
          value={currentEmail}
          onChange={(event) => {
            setCurrentEmail(event.target.value);
          }}
        />
        <Button onClick={addEmail}>
          Add
        </Button>
      </Stack>
    </Box>
  )
}