import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useApiClient } from "../utils/ApiClient";
import { useNavigate, Outlet } from "react-router-dom";
import { jobColumns } from "../data/columns";

import ViewHeader from "../components/ViewHeader";
import AddTaskIcon from "@mui/icons-material/AddTask";

export default function Jobs () {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    apiClient.jobs(false).then((response) => {
      setJobs(response.data.jobs);
    })
  }, []);

  return (
    <Box>
      <ViewHeader
        title={"Jobs"}
        actionIcon={<AddTaskIcon />}
        onActionClick={() => {
          navigate(`/jobs/create`);
        }}
      />

      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          columns={jobColumns}
          rows={jobs}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onSelectionModelChange={(ids) => {
            navigate(`/jobs/${ids[0]}`);
          }}
        />
      </Box>

      <Outlet />
    </Box>
  )
}