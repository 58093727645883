import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import AppRoutes from "./routes";
import reportWebVitals from "./reportWebVitals";

import { blue, green, red } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { AuthProvider, getLocalUser } from "./utils/auth.context";

// Import the moment locale to define the date/time format
import "moment/locale/en-gb";

import moment from "moment-timezone";

// Always use UTC within the app
moment.tz.setDefault("UTC");

const user = getLocalUser();
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    success: {
      main: green["A700"],
      contrastText: "#FFFFFF"
    },
    error: {
      main: red["A400"]
    },
    border: {
      main: "#E0E0E0"
    }
  },
});

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-GB"}>
      <ThemeProvider theme={theme}>
        <AuthProvider user={user}>
          <AppRoutes />
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
