const jobSkeleton = {
  id: 0,
  name: "",
  enabled: false,
  payload: {
    features: []
  },
  submitted: false,
  submissions: []
}

const submissionResultsSkeleton = {
  id: 1,
  name: "",
  startTime: "2022-10-10T12:00:00",
  requestId: 0,
  ncep: {},
  ecmwf: {}
}

const fileDataSkeletonGenerator = (filename) => {
  return {
    filename: filename ?? "",
    data: {}
  }
}

export {
  jobSkeleton,
  submissionResultsSkeleton,
  fileDataSkeletonGenerator
}