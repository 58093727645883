import { Alert } from "@mui/material";

const RequestStatus = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILURE: "failure"
}

function defaultStatus () {
  return {
    status: RequestStatus.PENDING,
    message: ""
  }
}

function RequestStatusAlert (props) {
  const severity = props.status === RequestStatus.SUCCESS ? "success" : "error";

  if (props.status == RequestStatus.PENDING) return "";
  
  return (
    <Alert severity={severity} sx={props.sx}>{props.message}</Alert>
  )
}

export {
  defaultStatus,
  RequestStatus,
  RequestStatusAlert
}