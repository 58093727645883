import { useEffect, useState } from "react";
import { formatDateTime } from "../../utils/format";
import { useApiClient } from "../../utils/ApiClient";
import { submissionResultsSkeleton } from "../../data/skeletons";
import { tracesFromResults, refTimeShape } from "../../utils/charts";
import { Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { RequestStatus, defaultStatus, RequestStatusAlert } from "../../components/RequestStatus";

import Plot from "react-plotly.js";

import Card from "../Card";
import SimpleSelect from "../SimpleSelect";

const moment = require("moment");

export default function SingleRunCard (props) {
  const apiClient = useApiClient();

  // line2cross names for the current submission
  const [lines2cross, setLines2cross] = useState({
    current: null,
    available: []
  })

  // Custom/additional refTimes and the checkbox
  // control that decides whether to display them.
  const [customRefTimes, setCustomRefTimes] = useState([]);
  const [customRefTimesEnabled, setCustomRefTimesEnabled] = useState(false);

  // Submission select
  const [submissions, setSubmissions] = useState([]);
  const [currentSubmissionId, setCurrentSubmissionId] = useState(null);

  // All submission results
  const [submissionResults, setSubmissionResults] = useState([]);

  // Submission result for plotting
  const [currentSubmissionResultId, setCurrentSubmissionResultId] = useState(null);
  const [currentSubmissionResult, setCurrentSubmissionResult] = useState({ ...submissionResultsSkeleton });

  const [plotData, setPlotData] = useState([]);
  const [supportPlotRange, setSupportPlotRange] = useState([0, 144])

  const [boxPlotTicks, setBoxPlotTicks] = useState([]);
  const [boxPlotTickLabels, setBoxPlotTickLabels] = useState([]);

  const [dataRequestStatus, setDataRequestStatus] = useState(defaultStatus());

  const resultsLabel = (results, isTitle) => {
    const formattedStartTime = `Start Time: ${formatDateTime(moment(results.modelStartTime))}`;
    const startTimeString = isTitle ? `<br>${formattedStartTime}` : ` - ${formattedStartTime}`;

    return `Run #${results.requestId} - Times to ${results.name} - Job "${results.job}"${startTimeString}`;
  };

  useEffect(() => {
    apiClient.submissionResults().then(response => {
      const sortedResults = response.data.results.sort((a, b) => a.requestId - b.requestId);
      const requestIds = new Set(sortedResults.map(x => x.requestId))
      const remoteSubmissions = [...requestIds].map(requestId => {
        const res = sortedResults.filter((res) => res.requestId === requestId).pop();

        return {
          label: `Run #${requestId} (${res.job}) - ${formatDateTime(moment(res.modelStartTime))}`,
          value: requestId,
          jobId: res.jobId
        }
      })

      remoteSubmissions.reverse()

      setSubmissions(remoteSubmissions)
      setSubmissionResults(sortedResults)
    })

    apiClient.customRefTimes().then((response) => {
      setCustomRefTimes(response.data.refTimes);
    })
  }, [])

  const onSubmissionSelected = (requestId) => {
    setCurrentSubmissionId(requestId);
    setLines2cross({
      current: null,
      available: submissionResults.filter((res) => res.requestId === requestId).map((res) => {
        return {
          label: res.name,
          // The submission result id linked to this line2cross
          value: res.id
        }
      })
    })
  }

  const simulationStepCount = (result) => {
    const ncepSteps = Object.keys(result.ncep).length;
    const ecmwfSteps = Object.keys(result.ecmwf).length;

    if (ncepSteps > ecmwfSteps) {
      return Object.keys(result.ncep).map(x => parseInt(x));
    }

    return Object.keys(result.ecmwf).map(x => parseInt(x));
  }

  const onSubmissionResultsSelected = (resultId) => {
    apiClient.submissionResult(resultId).then((response) => {
      setCurrentSubmissionResult(response.data);

      // Transform the data to be usable by plotly.
      // This works both for normal and single model submissions
      const plots = [];
      const trendlines = [];
      const ensembles = [];
      const spreads = [];
      const simulationSteps = simulationStepCount(response.data);

      ["ncep", "ecmwf"].map(key => {
        if (Object.keys(response.data[key]).length > 0) {
          const [boxPlot, trendlinePlot, spreadTrace, ensembleTrace] = tracesFromResults(response.data[key], key)

          plots.push(boxPlot);
          trendlines.push(trendlinePlot);
          spreads.push(spreadTrace);
          ensembles.push(ensembleTrace);
        }
      })

      const ticks = (
        // Sometimes we only have one plot, the ternary covers that case
        plots[0].tickvals.length > (plots.length > 1 ? plots[1].tickvals.length : -1)
      ) ? plots[0].tickvals : plots[1].tickvals;

      const formattedTicks = ticks.map((t) => {
        return formatDateTime(
          moment(response.data.startTime).add(parseInt(t), "hours"),
          true
        )
      })

      setDataRequestStatus(defaultStatus());
      setBoxPlotTicks(ticks);
      setBoxPlotTickLabels(formattedTicks);
      setSupportPlotRange([
        simulationSteps[0] - 12,
        simulationSteps.pop() + 12
      ])

      setPlotData([
        ...plots,
        ...trendlines,
        ...spreads,
        ...ensembles
      ]);
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load plot: ${message}`
      })
    })

    setCurrentSubmissionResultId(resultId);
  }

  const currentLine2Cross = lines2cross.available.filter((line) => line.value === lines2cross.current).pop()
  const line2crossCustomRefTimes = (customRefTimesEnabled && currentLine2Cross != null) ? customRefTimes
    .filter((r) => r.name === currentLine2Cross.label)
    .map((r) => refTimeShape(r.timeInDays)) : []

  return (
    <Card title="Single Run" sx={{ maxHeight: "1024px", ...props.sx ?? {} }}>
      <Stack direction="row" spacing={2}>
        <SimpleSelect
          fullWidth
          value={currentSubmissionId}
          options={props.job ? submissions.filter((o) => o.jobId === props.job.id) : submissions}
          label={"Submission"}
          onChange={(value) => onSubmissionSelected(value)}
        />

        <SimpleSelect
          fullWidth
          value={lines2cross.current}
          options={lines2cross.available}
          label={"Line to Cross"}
          onChange={(value) => {
            setLines2cross({
              current: value,
              available: lines2cross.available
            })

            onSubmissionResultsSelected(value);
          }}
        />

        <FormGroup>
          <FormControlLabel
            checked={customRefTimesEnabled}
            control={<Checkbox />}
            label="Custom refTimes"
            sx={{ mt: "auto", mb: "auto" }}
            onChange={(event) => {
              setCustomRefTimesEnabled(event.target.checked)
            }}
          />
        </FormGroup>
      </Stack>

      <RequestStatusAlert
        sx={{ mb: 0, mt: 2 }}
        status={dataRequestStatus.status}
        message={dataRequestStatus.message}
      />

      {currentSubmissionResultId && (
        <Plot
          style={{ width: "100%", height: "100%", minHeight: "768px" }}
          data={plotData}
          layout={{
            autosize: true,
            title: resultsLabel(currentSubmissionResult, true),
            boxmode: "group",
            grid: { rows: 2, columns: 1, pattern: "independent" },
            xaxis: {
              tickvals: boxPlotTicks,
              ticktext: boxPlotTickLabels,
              automargin: true,
              zeroline: false
            },
            yaxis: {
              title: {
                text: "Sail Time [days]"
              },
              zeroline: false
            },
            xaxis2: {
              zeroline: false,
              tickvals: boxPlotTicks,
              ticktext: boxPlotTickLabels,
              automargin: true,
              range: supportPlotRange
            },
            yaxis2: {
              zeroline: false,
              title: { text: "Spread Index (%)" },
              range: [-10, 110],
            },
            yaxis3: {
              side: "right",
              range: [-10, 110],
              overlaying: "y2",
              title: "Ensembles Completed (%)",
              zeroline: false
            },
            showlegend: true,
            shapes: "refTime" in currentSubmissionResult ? [
              refTimeShape(currentSubmissionResult.refTime),
              ...line2crossCustomRefTimes
            ] : [...line2crossCustomRefTimes]
          }}
        />
      )}
    </Card>
  )
}