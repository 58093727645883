/**
 * Creates a copy of the input object with the
 * target field updated with the given value
 * 
 * @param {object} object: the object to be updated
 * @param {string} field: the field to be updated, can be nested (e.g. a.b.c)
 * @param {*} value: the new field value
 * @returns
 */
const updateObjectField = (object, field, value) => {
  const updated = structuredClone(object);

  if (field.includes(".")) {
    // Create a list of nested fields, minus the last one
    const components = field.split(".");
    const target = components.pop();

    // Extract nested objects
    let currentObject = updated;
    components.forEach((component) => {
        currentObject = currentObject[component];
    });

    currentObject[target] = value;
  } else {
    // For un-nested fields simply update the property
    updated[field] = value;
  }

  return updated;
}

/**
 * Returns whether the given value
 * represents a valid integer or not.
 * null values are considered valid
 * integers
 *
 * @param {*} value
 */
const isValidInteger = (value) => {
  return value == null || /^\d+$/.test(value)
}

export {
  isValidInteger,
  updateObjectField
}