import { Box, Typography, Grid } from "@mui/material";

import Card from "../components/Card";

export default function DataCard (props) {
  return (
    <Card>
      <Box sx={{ pl: 0.75, pr: 0.75 }}>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontSize: 16 }}
        >
          {props.title}
        </Typography>

        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontSize: 20 }}
        >
          {props.content}
        </Typography>
      </Box>
    </Card>
  )
}