import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useApiClient } from "../utils/ApiClient";
import { percentageFormatter } from "../data/format";
import { RequestStatus, defaultStatus, RequestStatusAlert } from "../components/RequestStatus";

import ViewHeader from "../components/ViewHeader";
import DataCardGroup from "../components/DataCardGroup";

export default function SystemStatus () {
  const apiClient = useApiClient();

  const [systemStatus, setSystemStatus] = useState({});
  const [dataRequestStatus, setDataRequestStatus] = useState(defaultStatus());

  useEffect(() => {
    apiClient.systemStatus().then((response) => {
      setSystemStatus(response.data);
      setDataRequestStatus(defaultStatus());
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load the system status: ${message}`
      })
    })
  }, [])

  const subs = systemStatus.submissions;
  const ncep = systemStatus.gribs ? systemStatus.gribs.ncep : {};
  const ecmwf = systemStatus.gribs ? systemStatus.gribs.ecmwf : {};

  return (
    <Box>
      <ViewHeader title={"System Status"} />

      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={dataRequestStatus.status}
        message={dataRequestStatus.message}
      />

      {Object.keys(systemStatus).length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DataCardGroup
              title={"Submissions"}
              cards={[
                { title: "Total", content: subs.total },
                {
                  title: "Successful",
                  content: `${subs.successful} (${percentageFormatter({ value: subs.successful / subs.total })})`
                },
                {
                  title: "With Results", 
                  content: `${subs.results} (${percentageFormatter({ value: subs.results / subs.total })})`
                }
              ]}
            />
          </Grid>

          <Grid item xs={4}>
            <DataCardGroup
              title={"System Status | NCEP"}
              cards={[
                {
                  title: "NCEP Availability @ 00:00",
                  content: percentageFormatter({ value: ncep["00:00"].availability })
                },
                { title: "NCEP Average Time @ 00:00", content: ncep["00:00"].averageTime },
                {
                  title: "NCEP Availability @ 12:00",
                  content: percentageFormatter({ value: ncep["12:00"].availability })
                },
                { title: "NCEP Average Time @ 12:00", content: ncep["12:00"].averageTime }
              ]}
            />
          </Grid>

          <Grid item xs={4}>
            <DataCardGroup
              title={"System Status | ECMWF"}
              cards={[
                {
                  title: "ECMWF Availability @ 00:00",
                  content: percentageFormatter({ value: ecmwf["00:00"].availability })
                },
                { title: "ECMWF Average Time @ 00:00", content: ecmwf["00:00"].averageTime },
                {
                  title: "ECMWF Availability @ 12:00",
                  content: percentageFormatter({ value: ecmwf["12:00"].availability })
                },
                { title: "ECMWF Average Time @ 12:00", content: ecmwf["12:00"].averageTime }
              ]}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}