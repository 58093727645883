import { Typography } from "@mui/material";

export default function CardLabel (props) {
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{
          fontWeight: 400,
          color: "rgba(0, 0, 0, 0.64)",
          ...props.sx
      }}
    >
      {props.text.toUpperCase()}
    </Typography>
  );
}