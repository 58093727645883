import { Box, Typography, Stack, Tooltip } from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function FieldDisplay (props) {
  const header = (
    <Typography
      variant="subtitle1"
      component="div"
      className="vertical-center"
      sx={{ fontWeight: 500 }}
    >
      {props.name}
    </Typography>
  )

  return (
    <Box sx={props.sx}>
      {/* Show an help icon with a tooltip if needed. Otherwise just display the header */}
      {props.tooltipText && (
        <Stack direction="row">
          {header}

          <Box sx={{ flexGrow: 1 }} />

          <Tooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{props.tooltipText}</div>}
          >
            <HelpOutlineIcon
              sx={{ color: "icon.main" }}
              className="vertical-center"
            />
          </Tooltip>
        </Stack>
      )}

      {!props.tooltipText && (header)}

      {/* Display any React nodes from props.content */}
      {props.content}

      {/* If props.value is specified display a label */}
      {props.value && (
        <Typography
          variant="body2"
          component="div"
        >
          {props.value}
        </Typography>
      )}

      {props.helperText && (
        <Typography
          variant="body2"
          component="div"
          className="field-helper-text"
          sx={{ mt: 0.25 }}
        >
          {props.helperText}
        </Typography>
      )}
    </Box>
  )
}