import { intFormatter } from "./format";

const modelsValueGetter = (params) => {
  return params.row.models.join(", ")
}

const isoDateValueGetter = (params) => {
  return new Date(params.row[params.field]);
}

const jobColumns = [
  { field: "id", headerName: "ID", width: 100, type: "number", valueFormatter: intFormatter },
  { field: "name", headerName: "Name", width: 350 },
  { field: "nextSubmissionDate", headerName: "Next Submission Date (UTC)", width: 350, type: "dateTime", valueGetter: isoDateValueGetter },
  {
    field: "models",
    headerName: "Models",
    width: 150,
    type: "string",
    valueGetter: modelsValueGetter
  }
];

const jobSubmissionColumns = [
  { field: "requestId", headerName: "Request ID", width: 150, type: "number", valueFormatter: intFormatter },
  { field: "date", headerName: "Date (UTC)", width: 200, type: "dateTime", valueGetter: isoDateValueGetter },
  {
    field: "models",
    headerName: "Models",
    width: 150,
    type: "string",
    valueGetter: modelsValueGetter
  },
  { field: "statusCode", headerName: "Status Code", width: 100, type: "integer" }
];

const customRefTimesColumns = [
  { field: "name", headerName: "Name", type: "string", flex: 1 },
  { field: "time", headerName: "Ref Time (Hours)", width: 200, type: "number", valueFormatter: intFormatter },
]

export {
  jobColumns,
  jobSubmissionColumns,
  customRefTimesColumns
};