import { useEffect, useState } from "react";
import { useApiClient } from "../../utils/ApiClient";

import Card from "../Card";
import SimpleSelect from "../SimpleSelect";

export default function InsightsConfigurationCard (props) {
  const apiClient = useApiClient();

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    apiClient.jobs(true).then((response) => {
      setJobs(response.data.jobs);
    })
  }, []);

  return (
    <Card title="Configuration" sx={props.sx}>
      <SimpleSelect
        fullWidth
        label={"Job"}
        value={props.job ? props.job.id : null}
        onChange={(selectedJobId) => {
          props.onJobSelected(
            jobs.filter((job) => job.id === selectedJobId)[0]
          )
        }}
        options={jobs.map((job) => {
          return {
            label: `${job.name} (${job.id})`,
            value: job.id
          }
        })}
      />
    </Card>
  )
}