import { useEffect, useState } from "react";
import { calculateMean } from "../../utils/math";
import { formatDate, formatDateTime } from "../../utils/format";
import { useApiClient } from "../../utils/ApiClient";
import { deduplicateResults } from "../../utils/data";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { boxPlotTrace, refTimeShape, trendlineTrace, zerolineShape } from "../../utils/charts";
import { Stack, TextField, Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { RequestStatus, defaultStatus, RequestStatusAlert } from "../../components/RequestStatus";

import Plot from "react-plotly.js";

import Card from "../Card";
import SimpleSelect from "../SimpleSelect";

const _ = require("lodash");
const moment = require("moment");

export default function CompareFirstStepsCard (props) {
  const apiClient = useApiClient();

  const [dataRequestStatus, setDataRequestStatus] = useState(defaultStatus());
  const [timeframe, setTimeframe] = useState({
    start: null,
    end: null
  })

  const [customRefTimes, setCustomRefTimes] = useState([]);
  const [customRefTimesEnabled, setCustomRefTimesEnabled] = useState(false);

  const [selectedLine2cross, setSelectedLine2cross] = useState("finish");
  const [lines2cross, setLines2cross] = useState([{
    label: "finish",
    value: "finish"
  }]);

  const [plotData, setPlotData] = useState({
    traces: [],
    ticktext: [],
    tickvals: [],
    refTime: null
  });

  const compare = () => {
    const jobId = props.job ? props.job.id : null

    if (timeframe.start == null) {
      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: "Could not run comparison: invalid start date"
      })

      return;
    }

    if (timeframe.end == null) {
      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: "Could not run comparison: invalid end date"
      })

      return;
    }

    apiClient.compareFirstSteps(
      timeframe,
      selectedLine2cross,
      jobId
    ).then((response) => {
      const rawResults = response.data.results;

      if (rawResults.length === 0) {
        setDataRequestStatus({
          status: RequestStatus.FAILURE,
          message: "Could not run comparison: no data for the selected interval"
        })

        return
      }

      const results = deduplicateResults(rawResults);

      const trace = {
        ncep: { x: [], y: [] },
        ecmwf: { x: [], y: [] }
      }

      const means = {
        ncep: { x: [], y: [] },
        ecmwf: { x: [], y: [] }
      }

      const nao = { x: [], y: [] }

      const ticks = [];
      const ticktext = [];

      results.forEach((result) => {
        const ncepData = result.ncep;
        const ecmwfData = result.ecmwf;

        const label = result.startTimeTimestamp;

        const timeLabel = formatDateTime(moment(result.startTime), true)
        const textLabel = `#${result.requestId} ${timeLabel}`

        const naoValue = props.naoValues[formatDate(moment(result.startTime), "-")] ?? null;

        ticks.push(label);
        ticktext.push(textLabel);

        nao.x.push(label);
        nao.y.push(naoValue);

        trace.ncep.y = trace.ncep.y.concat(ncepData);
        trace.ncep.x = trace.ncep.x.concat(
          Array(ncepData.length).fill(parseInt(label))
        )

        trace.ecmwf.y = trace.ecmwf.y.concat(ecmwfData);
        trace.ecmwf.x = trace.ecmwf.x.concat(
          Array(ecmwfData.length).fill(parseInt(label))
        )

        // Create trendline data, skipping the submissions
        // for which the mean is null, otherwise plotly
        // may misbehave
        const ncepMean = calculateMean(ncepData)
        const ecmwfMean = calculateMean(ecmwfData)

        if (ncepMean != null) {
          means.ncep.x.push(parseInt(label))
          means.ncep.y.push(ncepMean)
        }

        if (ecmwfMean != null) {
          means.ecmwf.x.push(parseInt(label))
          means.ecmwf.y.push(ecmwfMean)
        }
      })

      // Create the NAO trace
      const naoTrace = {
        ...nao,
        mode: "lines+markers",
        type: "scatter",
        name: "NAO",
        xaxis: "x2",
        yaxis: "y2",
        marker: {
          size: 12,
          color: "#e74c3c"
        }
      }

      setPlotData({
        ticktext: ticktext,
        tickvals: ticks,
        refTime: response.data.refTime,
        traces: [
          boxPlotTrace(trace.ncep.x, trace.ncep.y, "ncep"),
          boxPlotTrace(trace.ecmwf.x, trace.ecmwf.y, "ecmwf"),
          trendlineTrace(means.ncep.x, means.ncep.y, "ncep"),
          trendlineTrace(means.ecmwf.x, means.ecmwf.y, "ecmwf"),
          naoTrace
        ]
      });

      setDataRequestStatus(defaultStatus());
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not run comparison: ${message}`
      })
    })
  }

  useEffect(() => {
    apiClient.lines2cross().then((response) => {
      setLines2cross(response.data.lines2cross.map((name) => {
        return {
          label: name,
          value: name
        }
      }))

      setDataRequestStatus(defaultStatus())
      apiClient.customRefTimes().then((response) => {
        setCustomRefTimes(response.data.refTimes);
      })
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();

      setDataRequestStatus({
        status: RequestStatus.FAILURE,
        message: `Could not load lines to cross: ${message}`
      })
    })
  }, [])

  const line2crossCustomRefTimes = customRefTimesEnabled ? customRefTimes
    .filter((r) => r.name === selectedLine2cross)
    .map((r) => refTimeShape(r.timeInDays)) : []

  return (
    <Card title="Compare First Steps" sx={{ maxHeight: "1280px", ...props.sx ?? {} }}>
      <Stack direction="row" spacing={2}>
        <SimpleSelect
          fullWidth
          value={selectedLine2cross}
          options={lines2cross}
          label={"Line to Cross"}
          onChange={(value) => setSelectedLine2cross(value)}
        />

        <DateTimePicker
          label="Start Date (UTC)"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={timeframe.start}
          onChange={(value) => {
            setTimeframe({
              ...timeframe,
              start: value
            })
          }}
        />

        <DateTimePicker
          label="End Date (End)"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={timeframe.end}
          onChange={(value) => {
            setTimeframe({
              ...timeframe,
              end: value
            })
          }}
        />

        <FormGroup>
          <FormControlLabel
            checked={customRefTimesEnabled}
            control={<Checkbox />}
            label="Custom refTimes"
            sx={{ mt: "auto", mb: "auto" }}
            onChange={(event) => {
              setCustomRefTimesEnabled(event.target.checked)
            }}
          />
        </FormGroup>

        <Button onClick={compare}>
          Compare
        </Button>
      </Stack>

      <RequestStatusAlert
        sx={{ mb: 0, mt: 2 }}
        status={dataRequestStatus.status}
        message={dataRequestStatus.message}
      />

      {plotData.traces.length > 0 && (
        <Plot
          style={{ width: "100%", height: "100%", minHeight: "768px" }}
          data={plotData.traces}
          layout={{
            autosize: true,
            boxmode: "group",
            grid: { rows: 2, columns: 1, pattern: "independent" },
            xaxis: {
              zeroline: false,
              tickvals: plotData.tickvals,
              ticktext: plotData.ticktext,
              automargin: true
            },
            yaxis: {
              domain: [0.55, 1],
              zeroline: false,
              title: {
                text: "Sail Time [days]"
              }
            },
            xaxis2: {
              tickvals: plotData.tickvals,
              ticktext: plotData.ticktext,
              zeroline: false,
              automargin: true
            },
            yaxis2: {
              domain: [0, 0.4],
              zeroline: false,
              range: [-3.5, 3],
              title: {
                text: "NAO value"
              }
            },
            showlegend: true,
            shapes: plotData.refTime ? [
              zerolineShape("x2", "y2"),
              refTimeShape(plotData.refTime),
              ...line2crossCustomRefTimes
            ] : [
              zerolineShape("x2", "y2"),
              ...line2crossCustomRefTimes
            ]
          }}
        />
      )}
    </Card>
  );
}