import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../utils/errors";
import { useApiClient } from "../utils/ApiClient";
import { Box, TextField, Button } from "@mui/material";
import { RequestStatusAlert, defaultStatus } from "../components/RequestStatus";

import Card from "../components/Card";
import ViewHeader from "../components/ViewHeader";
import JobSummary from "../components/JobSummary";
import JsonDropzone from "../components/JsonDropzone";

export default function CreateJob () {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const [uploadRequestStatus, setUploadRequestStatus] = useState(defaultStatus());

  const [name, setName] = useState("");
  const [configuration, setConfiguration] = useState({
    filename: "",
    data: {
      features: []
    }
  });

  const saveJob = () => {
    apiClient.saveJob(name, configuration.data).then((response) => {
      navigate(`/jobs/${response.data.id}`);
    }).catch((error) => {
      handleApiError(
        setUploadRequestStatus,
        error
      )
    });
  }

  return (
    <Box>
      <ViewHeader title={"Create Job"} />

      <RequestStatusAlert
        sx={{ mb: 2 }}
        status={uploadRequestStatus.status}
        message={uploadRequestStatus.message}
      />

      <Card title={"Job Details"}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />

        <JobSummary
          sx={{ mt: 2 }}
          filename={configuration.filename}
          job={{
            payload: configuration.data,
            submission: { id: null, model: null }
          }}
        />

        <JsonDropzone
          label="Drag 'n' drop the project file here or click to browse"
          onDrop={(acceptedFiles) => {
            var reader = new FileReader();

            reader.onload = function (event) {
              setConfiguration({
                filename: acceptedFiles[0].name,
                data: JSON.parse(event.target.result)
              })
            };

            reader.readAsText(acceptedFiles[0]);
          }}
        />
      </Card>
      <Box sx={{ mt: 2, textAlign: "right" }}>
        <Button variant="contained" onClick={saveJob}>
          Save
        </Button>
      </Box>
    </Box>
  )
}