import { Box, Stack } from "@mui/material";

import DataCard from "../components/DataCard";
import CardLabel from "../components/CardLabel";

export default function DataCardGroup (props) {
  return (
    <Box sx={props.sx}>
      <CardLabel text={props.title} sx={{ mb: 1 }} />

      <Stack spacing={2} sx={{ mb: 2 }}>
        {props.cards.map((card) => {
          return (
            <DataCard
              key={card.title}
              title={card.title}
              content={card.content}
            />
          )
        })}
      </Stack>
    </Box>
  )
}