import { Box, Typography, Stack } from "@mui/material";

import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function JsonDropzone (props) {
  const propsSx = props.sx ?? {}
  const iconProps = {
    fontSize: "large",
    sx: {
      ml: "auto",
      mr: "auto",
      color: "rgba(0, 0, 0, 0.84)"
    }
  }

  return (
    <Dropzone
      onDrop={props.onDrop}
      maxFiles={1}
      accept={{ "application/json": [".json"] }}
    >
      {({ getRootProps, getInputProps }) => (
        <Box {...getRootProps()} sx={{ mt: 2, ...propsSx }}>
          <input {...getInputProps()} />

          <Box
            sx={{
              mt: 0,
              display: "flex",
              height: 128,
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderStyle: "dashed"
            }}
          >
            <Stack spacing={1} sx={{ margin: "auto", textAlign: "center" }}>
              {props.icon && (<props.icon {...iconProps} />)}
              {!props.icon && (<CloudUploadIcon {...iconProps} />)}

              <Typography
                variant="body2"
                component="div"
              >
                {props.label}
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
    </Dropzone>
  );
}