import { useState, useEffect } from "react";
import { useApiClient } from "../../../utils/ApiClient";
import { Stack, Dialog, Button, TextField } from "@mui/material";
import { DialogTitle, DialogActions, DialogContent } from "@mui/material";

import SimpleSelect from "../../SimpleSelect";

export default function AddRefTimeDialog (props) {
  const apiClient = useApiClient();

  const [lines2cross, setLines2cross] = useState([])
  const [refTime, setRefTime] = useState({
    name: "",
    time: 0
  })

  const defaultValidationErrors = { name: "", time: "" }
  const [errors, setErrors] = useState(structuredClone(defaultValidationErrors))

  useEffect(() => {
    apiClient.lines2cross().then((response) => {
      setLines2cross(response.data.lines2cross.map((line) => {
        return {
          value: line,
          label: line
        }
      }))
    }).catch((error) => {
      const message = error.response ? error.response.data.message : error.toString();
      console.log(`Could not fetch lines2cross: ${message}`)
    })
  }, [])

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>
        Add refTime
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          <SimpleSelect
            fullWidth
            label={"line2cross"}
            value={refTime.name}
            options={lines2cross}
            error={errors.name.length > 0}
            helperText={errors.name}
            onChange={(value) => {
              setRefTime({
                ...refTime,
                name: value
              })
            }}
          />

          <TextField
            fullWidth
            type="number"
            label="Time"
            error={errors.time.length > 0}
            helperText={errors.time}
            value={refTime.time}
            onChange={(e) => {
              setRefTime({
                ...refTime,
                time: e.target.value
              })
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="error">Cancel</Button>
        <Button
          onClick={() => {
            const validationErrors = structuredClone(defaultValidationErrors)

            if (refTime.name.length <= 0) {
              validationErrors.name = "invalid name"
            }

            if (isNaN(parseInt(refTime.time)) || refTime.time <= 0) {
              validationErrors.time = "invalid time"
            }

            if (!(validationErrors.name.length > 0 || validationErrors.time.length > 0)) {
              setErrors(validationErrors)

              props.handleSave({
                name: refTime.name,
                time: parseInt(refTime.time)
              })

              setRefTime({
                name: "",
                time: 0
              })
            } else {
              setErrors(validationErrors)
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}